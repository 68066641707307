.main-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .chess-board-container {
    margin: 0px 20px;
    // TODO: Set width & height to be best for the user's device.
    width: 500px;
    height: 500px;

    .top-band {
      background-color: rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      padding: 16px;

      .dropdown {
        background: none;
      }

      .top-band-item {
        display: flex;
        align-items: center;

        .score {
          font-size: 24px;
          padding-left: 8px;
        }

        .time {
          font-size: 24px;
          padding-right: 8px;
        }
      }
    }

    .chess-board {
      position: relative;

      .chess-board-overlay {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        width: 100%;
        height: 100%;

        &.standby {
          background: rgba(0, 0, 0, 0.5);
          pointer-events: auto;
        }

        &.live {
          background: none;
          pointer-events: none;
        }

        .play-button {
          cursor: pointer;
        }

        .actual-square {
          font-size: 144px;
          font-weight: 900;

          &.white {
            text-shadow: 1px 1px black;
            color: white;
          }

          &.black {
            text-shadow: 1px 1px white;
            color: black;
          }
        }
      }
    }
  }
}